import enTranslations from "./en.json"
import deTranslations from "./de.json"
import chTranslations from "./ch.json"

const translations = {
  en: enTranslations,
  de: deTranslations,
  ch: chTranslations
}

export default translations