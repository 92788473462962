import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import translations from "../translations"
import LocalizedLink from "./LocalizedLink"
import "./base.scss"
import "./hamburger.css"
import logoImage from "../images/logo_trans.png"
import deFlagImage from "../images/flags/german.png"
import chFlagImage from "../images/flags/chinese.png"
import enFlagImage from "../images/flags/english.png"

class Header extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      menuOpen: false
    }

    this.toggleMenu = this.toggleMenu.bind(this)
  }

  toggleMenu(event) {
    this.setState(state => ({menuOpen: !state.menuOpen}))
  }

  render() {
    const { locale, siteTitle } = this.props

    return (
    <header className="header">
      <div className="container header-content">
        <div className="left">
          <LocalizedLink locale={locale} to="/" >
            <img src={logoImage} alt="Oulong Tech" />
          </LocalizedLink>
        </div>

        <div className="right">
          <ul className={this.state.menuOpen ? "menu open" : "menu"}>
            <li><LocalizedLink locale={locale} to="/about">{translations[locale].menu.aboutUs}</LocalizedLink></li>
            <li><LocalizedLink locale={locale} to="/product-development">{translations[locale].menu.productDevelopment}</LocalizedLink></li>
            <li><LocalizedLink locale={locale} to="/memory-test">{translations[locale].menu.innovativeMemoryTest}</LocalizedLink></li>
            <li><LocalizedLink locale={locale} to="/contact">{translations[locale].menu.contactUs}</LocalizedLink></li>
          </ul>
          <button className={this.state.menuOpen ? "hamburger hamburger--collapse is-active" : "hamburger hamburger--collapse"} type="button" onClick={this.toggleMenu}>
            <span className="hamburger-box">
              <span className="hamburger-inner"></span>
            </span>
          </button>

          <ul className="language-selection">
          {locale !== "en" &&
            <li><Link to="/"><img src={enFlagImage} alt="english" /></Link></li>
          }
          {locale !== "de" &&
            <li><Link to="/de"><img src={deFlagImage} alt="german" /></Link></li>
          }
          {locale !== "ch" &&
            <li><Link to="/ch"><img src={chFlagImage} alt="chinese" /></Link></li>
          }
          </ul>
        </div>
      </div>
    </header>
  )}

}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
