/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql, Link } from "gatsby"

import translations from "../translations"
import LocalizedLink from "./LocalizedLink"
import Header from "./header"
import "./base.scss"
import "./layout.scss"
import "./scroll-down.scss"

const Layout = ({ locale, children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <div className="site">
        <Header locale={locale} siteTitle={data.site.siteMetadata.title} />
        <main>
          {children}
        </main>
        <footer>
          <span>© {new Date().getFullYear()}, Oulong Technology GmbH</span>
          &nbsp;|&nbsp;<span><LocalizedLink locale={locale} to="/imprint">{translations[locale].footer.imprint}</LocalizedLink></span>
        &nbsp;|&nbsp;<span><LocalizedLink locale={locale} to="/contact">{translations[locale].footer.contact}</LocalizedLink></span>
        </footer>
      </div>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
