import React from "react"
import { Link } from "gatsby"

import locales from '../constants/locales'

const LocalizedLink = ({ to, locale, ...props}) => {
  const path = locales[locale].default ? to : `/${locale}${to}`
  return <Link {...props} to={path} />
}

export default LocalizedLink