module.exports = {
  en: {
    path: 'en',
    locale: 'English',
    default: true
  },
  de: {
    path: 'de',
    locale: 'German'
  },
  ch: {
    path: 'ch',
    locale: 'Chinese'
  }
}